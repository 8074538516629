<template>
  <b-container>
    <b-overlay rounded="sm" :show="show">
      <b-row class="mb-2 justify-content-end">
        <b-col md="8" lg="6">
          <div class="input-group">
            <input type="text" class="form-control" placeholder="Introduzca busqueda..." v-model="search">
            <div class="input-group-append">
              <button class="btn btn-success" @click="searchTour()" type="button"><feather-icon
                  icon="SearchIcon" /></button>
              <button class="btn btn-primary" @click="searchReset()" type="button"><feather-icon
                  icon="XIcon" /></button>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6" v-for="item, index in list" :key="index">
          <b-card no-body class="overflow-hidden">
            <b-card-header class="bg-warning">
              <b-card-title class="text-white">
                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                  stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                  <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path>
                  <line x1="4" y1="22" x2="4" y2="15"></line>
                </svg>
                {{ item.name }}{{ item.is_recurring == 1 ? ' - ' : '' }} <span class="is_recurring"
                  v-if="item.is_recurring == 1">Recurrente</span>
              </b-card-title>

            </b-card-header>

            <b-card-body>
              <div class="mt-2">
                <span class="mr-2"><strong>Fecha :</strong> {{ formatDate(item.tournament_date) }}
                  <strong>Hora:</strong>
                  {{ item.tournament_hour ? formattedTime(item.tournament_hour) : '' }} </span>
                <b-card-text class="mb-25"><strong>Nivel: </strong>{{ item.level }} <strong> - Número pistas:</strong>
                  {{ item.number_courts }}<br></b-card-text>
                <b-card-text>
                  <!-- <div v-for="match in item.matches">
                    <div v-if="match.couple1 && match.couple2">
                    <div v-if="(match.couple1.player1_id == user.player.id || match.couple1.player2_id == user.player.id) || (match.couple2.player1_id == user.player.id || match.couple2.player2_id == user.player.id)">
                      <p class="h4 mt-2"><strong>Partido  {{match.number_round == 0 ? '1ª Ronda' : (match.number_round == 1 ? '2ª Ronda' : (match.number_round == 2 ? '3ª Ronda' : '4ª Ronda'))}}</strong></p>                  
                      <p>{{ match.court.name + ' ' + match.court.number }}</p>
                      <p class="text-center"> {{ match.couple1 ? match.couple1.player1.full_name + ' - ' + match.couple1.player2.full_name : ''
                        }}
                      </p>
                      <p class="text-center">
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="green" stroke-width="2" fill="none"
                          stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                          <line x1="18" y1="6" x2="6" y2="18"></line>
                          <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                      </p>
                      <p class="text-center"> {{ (match.couple2 ? match.couple2.player1.full_name : '') + ' - ' + (match.couple2 ? match.couple2.player2.full_name : '') }}
                      </p>
                      <div class="text-center">
                        <button class="btn btn-success mr-2" v-if="!match.result" @click="openInsertResultModal(match.id)">Ganado</button>
                        <button class="btn btn-danger" v-if="match.loser_informed == 0" @click="inserResultLose(match.id)">Perdido</button>
                      </div>

                    </div>
                    </div>

                  </div> -->
                </b-card-text>
              </div>
            </b-card-body>

          </b-card>
        </b-col>
        <div class="">
          <h5 v-if="list.length == 0" class="text-danger h5">¡NO HAS PARTICIPADO EN TORNEOS AÚN!</h5>
        </div>       
      </b-row>
    </b-overlay>
    
  </b-container>

</template>
<script>
import { VBModal, BForm, BFormInput, BFormTextarea, BContainer, BRow, BCardHeader, BCard, BCardBody, BCardText, BButton, BCardTitle, BCol, BOverlay } from 'bootstrap-vue'
import { mapState } from 'vuex';
import moment from 'moment';
import vSelect from 'vue-select'
export default {
  props: ['type'],
  watch: {
    type: function () {
      this.show = true;
      this.search = '',
        this.list = [];
    }
  },
  components: { vSelect, VBModal, BForm, BFormInput, BFormTextarea, BContainer, BCardHeader, BCard, BRow, BCardBody, BCardText, BButton, BCardTitle, BCol, BOverlay },
  data() {
    return {
      show: false,
      list: [],
      // match_id: null,
      // couples: [],
      search: '',
    }
  },
  created() {
    this.show = true;
    this.$http.get("/api/getPlayerTournamentsPlayed?id=" + this.user.player.id).then((response) => {
      this.list = response.data;
      this.show = false;
    });
  },
  methods: {
  
    getOptionKey(option) {
      return option ? option.id : null;
    },
    
   
    searchTour() {
      this.show = true;
      this.$http.get("/api/getPlayerTournamentsPlayed?search="+this.search+'&id='+this.user.player.id).then((response) => {
        this.list = response.data;
        this.show = false;
      });
    },
    searchReset() {
      this.search = '';
      this.searchTour();
    },


    formattedTime(hour) {
      // Usando Moment.js para dar formato a la fecha
      return moment(hour, 'HH:mm').format('HH:mm'); // Formato de 12 horas con AM/PM
    },
    formatDate(date) {
      if (!date) return '';
      return moment(date).format('DD-MM-YYYY');
    },
  }, computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  }
}
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '@core/scss/vue/libs/vue-select.scss';

.green {
  background-color: green;
}

.grey {
  background-color: grey;
}

.is_recurring {
  background-color: rgb(67, 226, 226);
  color: black;
  padding: 0px 2px;
}

.bg-no-conf {
  background-color: rgb(231, 90, 109);
  padding: 2px;
  color: white;
}

.bg-yes-conf {
  background-color: rgb(90, 231, 114);
  padding: 2px 4px;
  color: rgb(0, 0, 0);
}

#insert-result-modal {
  margin-top: 40px;
}
</style>